import { props } from "ramda"
import { connect, ConnectedProps } from "react-redux"
import { PoemEntity } from "../../../../entities/PoemEntity"
import { ProfileEntity } from "../../../../entities/ProfileEntity"
import { UserEntity } from "../../../../entities/UserEntity"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (
  state: RootState,
  props: {
    pageContext: {
      haikus: PoemEntity[]
      user: UserEntity
      profile: ProfileEntity
    }
  }
) => {
  const isOwner = state.auth.user?.id === props.pageContext.user.id
  const haikus = isOwner ? state.profile.poems : props.pageContext.haikus
  const author = isOwner ? state.auth.user : props.pageContext.user
  const biography = isOwner
    ? state.profile.profile.biography
    : props.pageContext.profile.biography

  return {
    haikus,
    author,
    biography,
    isOwner,
    pageUserId: props.pageContext.user.id,
  }
}

const mapDispatch = (dispatch: any) => ({
  onMount: (id: string) => {
    dispatch(actions.auth.fetchIsAuthenticated()).then(
      ({ user, isConnected }) => {
        if (!isConnected || user.id !== id) return "do nothing"

        dispatch(actions.profile.fetchUserPoems())
        dispatch(actions.profile.fetchProfile())
      }
    )
  },
  onRemove: (id: PoemEntity["id"]) => {
    return dispatch(
      actions.modal.openCta({
        title: "Êtes-vous sur de vouloir supprimer ce haïku ?",
        description:
          "Si vous supprimez ce haïku, il sera définitivement perdu et vous ne pourrez pas revenir en arrière.",
        onSubmit: () => dispatch(actions.profile.fetchDestroyPoem(id)),
      })
    )
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
