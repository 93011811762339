import { connect, ConnectedProps } from "react-redux"
import { ProfileEntity } from "../../../entities/ProfileEntity"
import { UserEditableEntity } from "../../../entities/UserEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: { isOpen: boolean; onClose: Function }
) => ({
  biography: state.profile.editor.biography,
  firstName: state.auth.editor.firstName,
  lastName: state.auth.editor.lastName,
  isOpen: props.isOpen,
  onClose: props.onClose,
})

const mapDispatch = (dispatch: any) => ({
  onSave: () => dispatch(actions.profile.fetchUpdateUserInfo()),
  onChangeBiography(biography: ProfileEntity["biography"]) {
    dispatch(actions.profile.editBiography({ biography }))
  },
  onChangeUserInfo(user: UserEditableEntity) {
    dispatch(actions.auth.edit({ user }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
