import React from "react"
import { Transition } from "@headlessui/react"
import {
  connector,
  ContainerProps,
} from "./containers/ProfileInfoDrawer.container"

type Props = {
  biography: string | null
  firstName: string | null
  lastName: string | null
  onSave: Function
  isOpen: boolean
  onChangeBiography: Function
  onChangeUserInfo: Function
  onClose: Function
}

export const ProfileInfoDrawerWrapper: React.FC<Props> = props => {
  const biography = props.biography || ""
  const dash = Math.min(0, (100 * biography.length || 0) / 500 - 100)

  return (
    <>
      <Transition
        show={props.isOpen}
        enter="fixed z-10 ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="fixed z-10 ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-10 inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        </div>
      </Transition>

      <Transition
        show={props.isOpen}
        className="transform fixed z-10 transition ease-in-out  top-0 bottom-0 left-0 w-screen"
        enter="duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          className="absolute top-0 h-full right-0 pl-10 max-w-full flex sm:pl-16"
          aria-labelledby="slide-over-heading"
        >
          <div
            className="w-screen h-full fixed top-0 left-0 right-0 bottom-0"
            onClick={() => props.onClose()}
          ></div>
          <div className="w-screen max-w-2xl relative z-20">
            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
              <div className="flex-1">
                <div className="px-4 py-6 bg-gray-50 sm:px-6">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <h2
                        id="slide-over-heading"
                        className="text-lg font-medium text-gray-900"
                      >
                        Éditer mes informations
                      </h2>
                      <p className="text-sm text-gray-500">
                        Vous pouvez modifier vos informations et choisir
                        lesquelles seront visibles par le public.
                      </p>
                    </div>
                    <div className="h-7 flex items-center">
                      <button
                        onClick={() => props.onClose()}
                        className="rounded-md text-gray-400 hover:text-gray-800 hover:bg-gray-200 transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-indigo-500 p-2 -mr-2"
                      >
                        <span className="sr-only">Close panel</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Nom
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={props.lastName}
                        onChange={e =>
                          props.onChangeUserInfo({
                            lastName: e.target.value,
                            firstName: props.firstName,
                          })
                        }
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Prénom
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={props.firstName}
                        onChange={e =>
                          props.onChangeUserInfo({
                            lastName: props.lastName,
                            firstName: e.target.value,
                          })
                        }
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="biography"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Biographie
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="relative">
                        <div
                          className={`absolute bottom-0 right-0 p-1 select-none`}
                        >
                          <svg
                            width="24"
                            height="24"
                            className={`transform -rotate-90`}
                            viewBox="0 0 40 40"
                          >
                            <circle
                              cx="20"
                              cy="20"
                              r="14"
                              fill="none"
                              className="text-gray-200"
                              stroke="currentColor"
                              strokeWidth="6"
                            />
                            <circle
                              cx="20"
                              cy="20"
                              r="14"
                              fill="none"
                              className="text-blue-400"
                              strokeDashoffset={dash}
                              strokeDasharray="100"
                              stroke="currentColor"
                              strokeWidth="6"
                            />
                          </svg>
                        </div>

                        <textarea
                          id="biography"
                          name="biography"
                          rows={5}
                          value={props.biography}
                          onChange={e =>
                            props.onChangeBiography(e.target.value)
                          }
                          className="bg-transparent relative block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="p-4 text-sm italic text-gray-500">
                    Toute modification prendra effet dans 24 heures maximum,
                    <br />
                    merci de votre patience.
                  </div>
                </div>
              </div>

              <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                <div className="space-x-3 flex justify-end">
                  <button
                    type="button"
                    onClick={() => props.onClose()}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    onClick={() => props.onSave()}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export const ProfileInfoDrawerContainer: React.FC<ContainerProps> = props => (
  <ProfileInfoDrawerWrapper {...props} />
)

export const ProfileInfoDrawer = connector(ProfileInfoDrawerContainer)
