import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { PoemEntity } from "../../../entities/PoemEntity"
import { connector, ContainerProps } from "./containers/mes-haikus.container"
import { UserEntity } from "../../../entities/UserEntity"
import {
  Navigation,
  Footer,
  Hero,
  Breadcrumb,
} from "../../../components/Marketing"
import { Seo } from "../../../components/Seo/Seo"
import { CTA } from "../../../components/CTA/CTA"
import { ProfileInfoDrawer } from "../../../components/ProfileInfoDrawer/ProfileInfoDrawer"
import { Edit } from "./components/Edit"
import { Info } from "./components/Info"
import { Haikus } from "./components/Haikus"

type Props = {
  haikus: PoemEntity[]
  author: UserEntity
  biography: string | null
  isOwner: boolean
  pageUserId: string
  onMount?: Function
  onRemove?: Function
  onEdit?: Function
}

export const ProfileWrapper: React.FC<Props> = ({
  biography,
  pageUserId,
  haikus,
  author,
  onMount = () => false,
  onRemove = () => false,
  onEdit = () => false,
  isOwner,
}) => {
  useEffect(() => {
    onMount(pageUserId)
  }, [])

  const [isEditorOpen, setEditorOpen] = useState(false)

  const name = `${author.firstName} ${author.lastName}`

  const breadcrumbs = []

  const defaultBiography = `${name} n'a pas encore ajouté(e) de biographie. 
  
  Par contre l'auteur(e) a déjà conçu et publié(e) des haïkus, que vous pouvez retrouver ci-dessous.`

  return (
    <>
      <Seo
        title={`${name} | Ses haïkus et ses informations`}
        breadcrumbs={[]}
        index={false}
      />

      <Navigation />
      <Hero
        theme={{
          as: "div",
          value: <div></div>,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Les haïkus de
              <br />
              <span className="text-green-600">{name}</span>
            </>
          ),
        }}
        description={{
          as: "div",
          value: (
            <>
              <Info title="Biographie" as="h4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: (biography || defaultBiography).replace(
                      /\n/gim,
                      "<br/>"
                    ),
                  }}
                />
              </Info>

              {isOwner && <Edit onClick={() => setEditorOpen(true)} />}
            </>
          ),
        }}
      />

      <Haikus
        haikus={haikus}
        showControls={isOwner}
        authorId={author.id}
        onRemove={id => onRemove(id)}
      />

      <CTA />

      {isOwner && (
        <ProfileInfoDrawer
          isOpen={isEditorOpen}
          onClose={() => setEditorOpen(false)}
        />
      )}

      <Footer />
    </>
  )
}

export const ProfileContainer: React.FC<
  ContainerProps & RouteComponentProps
> = props => <ProfileWrapper {...props} />

export default connector(ProfileContainer)
