import { Link } from "gatsby"
import React, { useState } from "react"
import { Arrows } from "../../../../components/Arrows/Arrows"
import { PoemEntity } from "../../../../entities/PoemEntity"
import { ProfileEntity } from "../../../../entities/ProfileEntity"

type Props = {
  haikus: PoemEntity[]
  authorId: string
  showControls: boolean
  onRemove: (id: string) => any
}

export const Haikus: React.FC<Props> = props => {
  const [selected, setSelected] = useState(0)

  const haiku = props.haikus[selected] || props.haikus[0]

  const hasLeft = selected > 0
  const hasRight = selected < props.haikus.length - 1

  return (
    <>
      <div className="relative py-20 bg-gray-50 pt-28">
        {haiku && (
          <nav className={`w-full flex items-center`}>
            <div className="container">
              <div className="flex justify-center">
                <div>
                  <div
                    className="relative z-20 font-serif text-xl italic text-gray-700"
                    dangerouslySetInnerHTML={{ __html: haiku.html }}
                  ></div>

                  <p className="relative z-20 px-2 my-8 -mx-2 font-serif text-sm italic text-gray-500 border-l-2">
                    <span>{haiku.user.firstName}</span>{" "}
                    <span>{haiku.user.lastName}</span>
                  </p>
                  {props.showControls && (
                    <div className="relative z-10 grid grid-cols-2 mt-10 space-x-2">
                      <button
                        onClick={() => props.onRemove(haiku.id)}
                        className="px-10 py-3 text-xs font-medium text-gray-500 uppercase duration-300 border border-gray-200 rounded-full shadow-md hover:shadow-none hover:text-gray-800 hover:bg-white"
                      >
                        Supprimer
                      </button>
                      <Link
                        to={`/editer/?id=${haiku.id}`}
                        state={{
                          from: `/auteurs/${props.authorId}/`,
                        }}
                        className="px-10 py-3 text-xs font-medium text-gray-500 uppercase duration-300 border border-gray-200 rounded-full shadow-md hover:shadow-none hover:text-gray-800 hover:bg-white"
                      >
                        Modifier
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </nav>
        )}
        <Arrows
          hasLeft={hasLeft}
          hasRight={hasRight}
          onLeft={() => setSelected(selected - 1 || 0)}
          onRight={() => setSelected(selected + 1)}
        />
      </div>
    </>
  )
}
