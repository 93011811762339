import React from "react"

export const Edit: React.FC<{ onClick: Function }> = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    className="inline-flex items-center pl-6 py-3 mt-8 border text-base font-medium rounded-full text-gray-800 bg-white shadow-lg hover:shadow-sm border-gray-200 transition duration-150 ease-in"
  >
    <span>Éditer mes informations</span>
    <span className="pl-2 pr-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
        stroke="none"
        className="h-6 w-6"
      >
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    </span>
  </button>
)
